<template>
    <div class="image-input"
    @dragover.prevent="dragOver" 
    @dragleave="dragEnd"
    @dragend="dragEnd" 
    @drop.prevent="imageFromDrop"
    :class="{'image-input--active':  dragover || inputaccepted }">
        <div class="image-input__upload-area"
        :style="{ backgroundImage: placeholder ? `${placeholder}` : defaultplaceholder} ">
            <div class="image-input__overlay">
                <div v-show="loading" class="image-input__overlay-buttons">
                    <div v-if="loading"><filling-circle :size="32" color="#fff"/></div>
                </div>
                <div v-show="!loading" class="image-input__overlay-buttons">
                <button v-show="okayButtonShow && !control" @click="sendFIle" class="image-input__send">
                    <img src="@/assets/images/check.svg" width="16" alt="">
                </button>
                <button v-show="uploadButtonShow && !control" @click="imageFromInput" class="image-input__select-img">
                    <img src="@/assets/images/upload.svg" width="16" alt="">
                </button>
                <button v-show="closebutton && !control" @click="removeImage" class="image-input__cancel">x</button>
            </div>
            </div>
            <input ref="input" @change="inputChange" value="@/assets/images/logo-upload.png"
            type="file" accept="image/*" class="image-input__upload-button"> 
        </div>
    </div>
</template>
<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import defaultplaceholderImage from '@/components/Modals/ModalInputs/placeholder_base64.js'
export default {
    components:{ FillingCircle },
    props:{
        add: {
            type:Boolean,
        },
        id:{
            type:String
        },
        subId:{
            type:String,
        },
        image:{
            type:String,
        },
        control:{
            type:Boolean,
        }
    },
    data(){
        return{
            loading:false,
            dragover:false,
            inputaccepted: false,
            closebutton:true,
            placeholder: null,
            defaultplaceholder:defaultplaceholderImage,
            imageFile: null,
        }
    },
    methods:{
        dragOver(){
            this.dragover = true
        },
        dragEnd(){
            this.dragover = false
        },
        imageFromInput(){
            this.$refs.input.click()
        },
        inputChange(){
            this.uploadFile(this.$refs.input.files[0])
            console.log(this.$refs.input.value)
        },
        imageFromDrop(e){
            this.uploadFile(e.dataTransfer.files[0])
        },
        uploadFile(file){
            this.imageFile = file
            this.$emit('get-image',this.imageFile)
            const reader = new FileReader();
            
            reader.readAsDataURL(file)
            reader.onload = () => {
                if(file.type.startsWith("image/")){
                this.placeholder = `url(${reader.result})`
                console.log('placholder--',this.placeholder)
                this.inputaccepted = true
                this.closebutton = true
            }else{
                    alert("Input Must Be An Image!")
                }
            }   
        },
        sendFIle(){
            this.loading = true;
            console.log(this.placeholder)
            this.$store.dispatch({
                type: this.uploadRequest,
                logo: this.imageFile,
                id: this.id,
                ...(this.subId && {subId: this.subId})

            }).then((response)=>{
                this.$notify({
                    title: "Image Updated",
                    text:`Successfully changed Deleted image!`,
                    type:"success"
                })
                this.inputaccepted = false
                this.loading = false
                console.log(response)
            }).catch((error)=>{
                this.$notify({
                        title: "Failed to updater Image",
                        text: error,
                        type:"error"
                    })
                this.loading = false
                console.log(error.response.data)
            })
            

        },
        removeImage(){
            if(!this.add && !this.inputaccepted){
                this.loading = true;
                console.log(this.placeholder)
                this.$store.dispatch({
                    type: this.uploadRequest,
                    logo: null,
                    id: this.id,
                    ...(this.subId && {subId: this.subId})

                }).then((response)=>{
                    this.$notify({
                        title: "Image Removed",
                        text:`Successfully changed Deleted image!`,
                        type:"success"
                    })
                    this.loading = false
                    this.imageFile = null
                    this.placeholder = null
                    this.inputaccepted = false
                    this.closebutton = false
                    console.log(response)
                }).catch((error)=>{
                    this.$notify({
                        title: "Failed to remove Image",
                        text: error,
                        type:"error"
                    })
                    this.loading = false
                    console.log(error.response.data)
                })
            }else{
                this.imageFile = null
                this.placeholder = null
                this.inputaccepted = false
                this.closebutton = false
                this.$emit('get-image',null) 
            }


            
        }

    },

    computed:{
        okayButtonShow(){
            if(this.add===true) return false
            else return ( this.inputaccepted )
        },
        uploadButtonShow(){
            if(this.add===true) return true
            else return ( !this.inputaccepted )
        },
        uploadRequest(){
            return this.$route.meta['M_REQUESTS'].update
        },
    },

    mounted(){
        console.log(this.control)
        if(this.add){
            this.inputUploaded = true     
        }
        if(!this.image) this.closebutton = false
        this.placeholder = this.image ? `url(https://api.vendoorr.com${this.image}` : null
    }
}


</script>
